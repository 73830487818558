let config = {
    navBaseUrl: 'https://www.srpnet.com/standalone/header',
    navBaseUrlEs: 'https://www.srpnet.com/es/standalone/header',
    footerBaseUrl: 'https://www.srpnet.com/standalone/footer',
    footerBaseUrlEs: 'https://www.srpnet.com/es/standalone/footer',
    apiBaseUrl: 'https://myaccount.srpnet.com/myaccountapi/',
    appPath: '/power',
    srpnetBaseUrl: 'https://www.srpnet.com/',
    srpnetBaseUrlEs: 'https://www.srpnet.com/es/',
    waterBaseUrl: 'https://water.srpnet.com/',
    misrpBaseUrl: 'https://www.misrp.com/',
    googleMapsApiKey: 'AIzaSyD_zLAPFDl19j03bbxG9U0zpmR3LhCTQp8',
    googleMapsBeta: false,
    adobeLaunchScriptUrl: 'https://assets.adobedtm.com/launch-EN5271f390a21a41b1b0090c838408f067.min.js',
    homeEnergyProfile: 'https://srp.opower.com/ei/x/login-redirect?return-to=%2Fei%2Fx%2Fhome-energy-profile',
    energyInsights: 'https://srp.opower.com/ei/x/energy-insights',
    isProduction: true,
    appDynamics: {
        appKey: 'EUM-AAB-AUN',
        adrumExtUrl: 'https://cdn.appdynamics.com',
        beaconUrl: 'https://umon.srpnet.com',
        scriptUrl: 'https://cdn.appdynamics.com/adrum/adrum-20.12.0.3360.js'
    }
}

export default config